isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}


function checkMobile() {
    if (isMobile) {
        document.querySelector("body").classList.add("is--mobile");
        document.querySelector("body").classList.remove("is--desktop");
    } else {
        document.querySelector("body").classList.remove("is--mobile");
        document.querySelector("body").classList.add("is--desktop");
    }
}

checkMobile();

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let swiperScript = 0;


if (document.querySelectorAll(".header").length) {
    var navbar = document.querySelectorAll(".header")[0];

    function addFixedMenu() {
        if (window.pageYOffset >= 50) {
            navbar.classList.add("fixed-header-scroll");
            document.querySelector("body").classList.add("menu-is-fixed");
        } else {
            navbar.classList.remove("fixed-header-scroll");
            document.querySelector("body").classList.remove("menu-is-fixed");
        }
    }

    if (document.body.clientHeight > (window.innerHeight + 400)) {
        addFixedMenu();
        window.addEventListener("scroll", function (e) {
            addFixedMenu();
        });
    }
}


let slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
var slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}

const navItemToA = document.querySelectorAll('.nav__level-1 a');

for (let i = 0; i < navItemToA.length ; i++) {
    let btnNav=document.querySelector("#nav-toggle");
    navItemToA[i].addEventListener("click", function(){
        if(btnNav.classList.contains("open")){
            document.querySelector(".nav__wrapper").classList.remove("open");
            document.querySelector("body").classList.remove("menu-open");
            btnNav.classList.remove("open");
        }
    })
}

document.querySelector("#nav-toggle").addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.classList.toggle("open");
    if (this.classList.contains("open")) {

        document.querySelector("body").classList.add("menu-open");
        document.querySelector(".nav__wrapper").classList.add("open");

    } else {

        if (document.querySelector(".nav ul.open")) {
            document.querySelector(".nav__wrapper").classList.remove("open");
        }
        document.querySelector(".nav__wrapper").classList.remove("open");
        document.querySelector("body").classList.remove("menu-open");
    }
    e.preventDefault();
    e.stopPropagation();


})







function accordion(accordion) {

    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__trigger");

    accordionBtn.addEventListener("click", function (e) {

        e.preventDefault();
        if (accordionBtn.getAttribute("aria-expanded") == "true") {

            accordionBtn.setAttribute('aria-expanded', false);
            accordionContent.setAttribute('aria-hidden', true);
            if (accordion.classList.contains("active")) {
                accordion.classList.remove("active");
            }
            slideUp(accordionContent);

        } else {

            accordionBtn.setAttribute('aria-expanded', true);
            accordionContent.setAttribute('aria-hidden', false);
            accordion.classList.add("active");
            slideDown(accordionContent);
        }

    })
}

Array.prototype.map.call(document.querySelectorAll(".accordion"), function (item) {
    accordion(item)
});



(function () {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {

            modalTrigger[i].onclick = function (e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);
                document.querySelector("body").classList.add("modal-open");
                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function () {
                var modalWindow = this.parentNode.parentNode;
                document.querySelector("body").classList.remove("modal-open");
                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function () {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);


}());




//dodawanie produktów do tab-strona główna


if (document.getElementById("scrollToTop")) {
    document.getElementById("scrollToTop").addEventListener("click", function (e) {
        e.preventDefault();
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    })

}
if (document.getElementById("scrollDown")) {
    document.getElementById("scrollDown").addEventListener("click", function (e) {
        e.preventDefault();
        let down = window.pageYOffset;
        down += 300;
        window.scroll({
            top: down,
            left: 0,
            behavior: 'smooth'
        });
    })

}


function speakerToModal(item) {

    item.addEventListener("click", function () {

        let imgWrapper = document.querySelector("#speaker-details .speaker-details__img");
        imgWrapper.innerHTML = ""
        if (item.querySelector(".speaker__img")) {
            let img = (item.querySelector(".speaker__img")).cloneNode(true);

            imgWrapper.appendChild(img);
        }
        let name = (item.querySelector(".speaker__name")).cloneNode(true);

        let txt = (item.querySelector(".speaker__txt")).cloneNode(true);
        txt.classList.remove("speaker__txt");


        let nameWrapper = document.querySelector("#speaker-details .modal__title");
        nameWrapper.innerHTML = "";
        nameWrapper.appendChild(name);

        let txtWrapper = document.querySelector("#speaker-details .speaker-details__txt");
        txtWrapper.innerHTML = "";
        txtWrapper.appendChild(txt);
    })
}


Array.prototype.map.call(document.querySelectorAll(".speaker__item"), function (item) {
    speakerToModal(item)
});


if (document.querySelector(".speaker__showAll")) {
    let btn = document.querySelector(".speaker__showAll");
    let list = document.querySelector(".speaker__list");

    btn.addEventListener("click", function (e) {
        e.preventDefault();
        if (btn.classList.contains("active")) {
            btn.classList.remove("active");
            list.classList.remove("open");
        } else {
            btn.classList.add("active");
            list.classList.add("open");
        }
    })
}

if (document.querySelector(".save__left")) {

    document.querySelector(".save__left").style.minHeight = document.querySelector(".save__left").clientHeight + "px";
}